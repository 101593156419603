import { FeeManagementService } from '../../helpers/backend/fee_management.service';
import {
    FETCH_CHANNEL_LIST_FOR_FEE_MANAGEMENT,
    FETCH_FEE_CONFIG_META,
    FETCH_FEE_CONFIG_RECORDS,
    FETCH_PAYMENT_METHODS_OF_PAYMENT_CHANNEL
} from '../actions.type';
import {
    CREATE_FEE_CONFIG_RECORD_IN_STORE,
    DELETE_FEE_CONFIG_RECORD_IN_STORE,
    FEE_MANAGEMENT_ADD_CHANNEL_LIST_IN_STORE, 
    FEE_MANAGEMENT_ADD_FEE_CONFIG_META_IN_STORE,
    FEE_MANAGEMENT_ADD_FEE_CONFIG_RECORDS_IN_STORE,
    UPDATE_FEE_CONFIG_PSP_EMAIL_DETAILS_IN_STORE,
    UPDATE_FEE_CONFIG_RECORD_IN_STORE,
    UPDATE_FEE_CONFIG_SFTP_DETAILS_IN_STORE,
    UPDATE_FEE_CONFIG_TOLERANCE_LEVEL_IN_STORE,
    UPDATE_PAYMENT_METHODS_IN_STORE,
    FEE_MANAGEMENT_ADD_TOLERANCE_LEVEL_IN_STORE,
    RESET_FEE_CONFIG_STATE,
    CREATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE,
    UPDATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE
} from '../mutations.type';

export const state = {
    content: [],
    paymentChannelList:[],
    feeConfigMeta: {
        "tolerance_level": 0,
        "merchant_email": "",
        "merchant_psp_email": "",
        "sftp_link": "",
        "sftp_username": "",
        "sftp_password": "",
        "is_psp_email_configured": false,
        "is_sftp_configured": false
    },
    recon_email: "",
    recon_confirmation_code: "",
    recon_confirmation_link: "",
    paymentMethods: {},
    paymentMethodsList: []
}

export const mutations = {
    [FEE_MANAGEMENT_ADD_CHANNEL_LIST_IN_STORE](state, newChannelListData) {
		state.paymentChannelList = newChannelListData;
	},
    [FEE_MANAGEMENT_ADD_FEE_CONFIG_META_IN_STORE](state, feeConfigMeta) {
		state.feeConfigMeta = {...state.feeConfigMeta, ...feeConfigMeta.fee_config_metadata};
        state.recon_email = feeConfigMeta.recon_email;
        state.recon_confirmation_code = feeConfigMeta.recon_confirmation_code;
        state.recon_confirmation_link = feeConfigMeta.recon_confirmation_link;
	},
    [FEE_MANAGEMENT_ADD_FEE_CONFIG_RECORDS_IN_STORE](state, feeConfigRecords) {
		state.content = feeConfigRecords;
	},
    [UPDATE_PAYMENT_METHODS_IN_STORE](state, paymentMethods) {
        if(paymentMethods.currency_data) {
            const arr = [];
            for(let key of Object.keys(paymentMethods.currency_data)) {
              arr.push({
                value: key,
                text: paymentMethods.currency_data[key]['display_name']
              })
            }
            state.paymentMethodsList = arr;
        }
        state.paymentMethods = paymentMethods
    },
    [FEE_MANAGEMENT_ADD_TOLERANCE_LEVEL_IN_STORE](state, tolerance_level) {
        state.feeConfigMeta.tolerance_level = tolerance_level
	},
    [RESET_FEE_CONFIG_STATE](state) {
        state.content = [];
        state.paymentChannelList =[]
        state.feeConfigMeta = {
            "tolerance_level": 0,
            "merchant_email": "",
            "merchant_psp_email": "",
            "sftp_link": "",
            "sftp_username": "",
            "sftp_password": "",
            "is_psp_email_configured": false,
            "is_sftp_configured": false
        }
        state.recon_email = "";
        state.recon_confirmation_code = "";
        state.recon_confirmation_link = "";
        state.paymentMethods = {}
	}
}

export const getters = {
//   getPaymentLinksEntityDetails(state) {
//     return state.entityDetails;
//   }
}

export const actions = {
    [FETCH_CHANNEL_LIST_FOR_FEE_MANAGEMENT]({ commit, rootState }) {
		return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            FeeManagementService.fetchChannelListForFeeManagement(merchantUUId, iamportKey, environment).then((response) => {
                commit(FEE_MANAGEMENT_ADD_CHANNEL_LIST_IN_STORE, response.content)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [FETCH_FEE_CONFIG_META]({ commit, rootState }, { pcKey }) {
		return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            commit(RESET_FEE_CONFIG_STATE)
            FeeManagementService.fetchFeeConfigMeta(merchantUUId, iamportKey, pcKey, environment).then((response) => {
                commit(FEE_MANAGEMENT_ADD_FEE_CONFIG_META_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [FETCH_FEE_CONFIG_RECORDS]({ commit, rootState }, { pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            FeeManagementService.fetchFeeConfigRecords(merchantUUId, iamportKey, pcKey, environment).then((response) => {
                commit(FEE_MANAGEMENT_ADD_FEE_CONFIG_RECORDS_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [UPDATE_FEE_CONFIG_SFTP_DETAILS_IN_STORE]({ commit, rootState }, { configData, pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            const data = {
                "update_action": "UPDATE_SFTP_DETAILS",
                "config_data": configData,
                "environment": environment
            };

            FeeManagementService.updateFeeConfigMeta(merchantUUId, iamportKey, pcKey, environment, data).then((response) => {
                if(response.status_code == "2000") {
                    commit(FEE_MANAGEMENT_ADD_FEE_CONFIG_META_IN_STORE, configData)
                }
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [UPDATE_FEE_CONFIG_PSP_EMAIL_DETAILS_IN_STORE]({ commit, rootState }, { configData, pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            const data = {
                "update_action": "UPDATE_MERCHANT_PSP_EMAIL",
                "config_data": configData,
                "environment": environment
            };

            FeeManagementService.updateFeeConfigMeta(merchantUUId, iamportKey, pcKey, environment, data).then((response) => {
                if(response.status_code == "2000") {
                    commit(FEE_MANAGEMENT_ADD_FEE_CONFIG_META_IN_STORE, configData)
                }
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [UPDATE_FEE_CONFIG_TOLERANCE_LEVEL_IN_STORE]({ commit, rootState }, { toleranceLevel, pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            const data = {
                "tolerance_level": toleranceLevel,
                "update_action": "UPDATE_TOLERANCE_LEVEL",
                "config_data": {},
                "environment": environment
            };

            FeeManagementService.updateFeeConfigMeta(merchantUUId, iamportKey, pcKey, environment, data).then((response) => {
                if(response.status_code == "2000") {
                    commit(FEE_MANAGEMENT_ADD_TOLERANCE_LEVEL_IN_STORE, toleranceLevel)
                }
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [UPDATE_FEE_CONFIG_RECORD_IN_STORE]({ commit, rootState }, { configData, pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const data = {
                "config_data": configData,
                "environment": environment
            };

            FeeManagementService.updateFeeConfigRecord(iamportKey, pcKey, environment, data).then((response) => {
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [UPDATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE]({ commit, rootState }, {configData, paymentChannel}) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const data = {
                "config_data": configData,
                "environment": environment
            };

            FeeManagementService.updateFoodTechFeeConfigRecord(iamportKey, paymentChannel, environment, data).then((response) => {
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [FETCH_PAYMENT_METHODS_OF_PAYMENT_CHANNEL]({ commit, rootState }, { pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            FeeManagementService.fetchPaymentMethodsOfPaymentChannel(merchantUUId, iamportKey, pcKey, environment).then((response) => {
                commit(UPDATE_PAYMENT_METHODS_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [CREATE_FEE_CONFIG_RECORD_IN_STORE]({ commit, rootState }, { configData, pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;
            const data = {
                "config_data": {
                    "pm_key": configData.paymentMethod,
                    "currency": configData.currency,
                    "percent_fee": parseFloat(configData.percentFee),
                    "flat_fee": parseFloat(configData.flatFee),
                    "minimum_fee": parseFloat(configData.minimumFee),
                    "settlement_days": configData.settlementCycleDays,
                    "tax_percentage": parseFloat(configData.tax),
                    "is_apply_fee_enabled": configData.status,
                    "network_id": configData.network_id,
                    "rules": configData.rules,
                    "is_commercial": configData.is_commercial
                },
                "environment": environment
            };

            if(configData.bank_name) {
                data.config_data.bank_code = configData.bank_code.bank_code;
                data.config_data.bank_name = configData.bank_name;
                data.config_data.installment_term = configData.installment_term;
            }

            FeeManagementService.createFeeConfigRecord(merchantUUId, iamportKey, pcKey, environment, data).then((response) => {
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [CREATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE]({ commit, rootState }, configData) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const payload = {
                "config_data": configData,
                "environment": environment
            };

            FeeManagementService.createFeeConfigRecordFoodTech(iamportKey, configData.pm_key, environment, payload).then((response) => {
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

    [DELETE_FEE_CONFIG_RECORD_IN_STORE]({ commit, rootState }, { data, pcKey }) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            const merchantUUId = rootState.auth.currentUser.merchantUuid;

            FeeManagementService.deleteFeeConfigRecord(merchantUUId, iamportKey, pcKey, environment, data).then((response) => {
                resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

}

export const namespaced = true;
