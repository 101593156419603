import { MasterMerchantService } from '../../helpers/backend/master_merchant.service';
import moment from "moment";
import {
  FETCH_MERCHANTS_LIST,
  FETCH_MERCHANTS_PAGINATED_LIST,
  FETCH_INVITED_MERCHANTS_LIST,
  INVITE_MERCHANT_FOR_MANAGED,
  INVITE_MERCHANT_FOR_OWNED,
  ACTIVE_INACTIVE_MERCHANT,
  INVITE_MERCHANT,
  MERCHANT_RESEND_KYC,
  DELETE_MERCHANT,
  MERCHANT_RESEND_INVITE
} from '../actions.type';

import {
  ADD_MASTER_MERCHANT_LIST_IN_STORE,
  ADD_MASTER_MERCHANT_PAGINATED_LIST_IN_STORE,
  ADD_INVITED_MASTER_MERCHANT_LIST_IN_STORE,
  UPDATE_MASTER_MERCHANT_FETCHING_STATE,
} from '../mutations.type';

export const state = {
  isFetchingData: false,
  content: [],
  paginatedContent: [],
  invitedContent:[],
  total_elements: 0,
  total_pages: 0,
  size: 0,
  page: 0,
  number_of_elements: 0,
  // uploaded_data:[],
  summary:[],
  selected_date_for_summary:""
}

export const mutations = {
  [ADD_MASTER_MERCHANT_LIST_IN_STORE](state, newTransactionData) {
    state.content = newTransactionData.content;
  },
  [ADD_MASTER_MERCHANT_PAGINATED_LIST_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.paginatedContent = newTransactionData.content;
    state.total_elements = newTransactionData.total_elements;
    state.total_pages = newTransactionData.total_pages;
    state.size = newTransactionData.size;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.number_of_elements;
  },
  [ADD_INVITED_MASTER_MERCHANT_LIST_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.invitedContent = newTransactionData.content;
  },
  [UPDATE_MASTER_MERCHANT_FETCHING_STATE](state, isFetching) {
    state.isFetchingData = isFetching;
  }
}

export const getters = {
  getPaymentLinksEntityDetails(state) {
    return state.entityDetails;
  },
}

export const actions = {
  [INVITE_MERCHANT_FOR_MANAGED](context, { email,merchant_type }) {
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      MasterMerchantService.inviteMerchantForManagment(merchantUUID,email,merchant_type).then((response) => {
        // context.commit(SET_UPLOADED_DATA_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [INVITE_MERCHANT_FOR_OWNED](context, { email, merchant_type, name }) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      MasterMerchantService.inviteMerchantForOwnedType(merchantUUID, iamportKey, email, merchant_type, name).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [INVITE_MERCHANT](context, formData) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      MasterMerchantService.inviteMerchant(merchantUUID, iamportKey, formData).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },


  [FETCH_MERCHANTS_LIST]({ commit, rootState }, { pageIndex, pagesize, filters }) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      const environment = rootState.auth.environment;
      MasterMerchantService.fetchMerchantList(merchantUUID, iamportKey, pageIndex, pagesize, filters, environment).then((response) => {
        const merchant_list_content = response.content.map((merchant_list) => {
          return {
            email_id: merchant_list.email_address || "",
            key:merchant_list.key || "",
            uuid: merchant_list.uuid
          };
        });

        const merchant_listData = {
          content: merchant_list_content,
        }
        commit(ADD_MASTER_MERCHANT_LIST_IN_STORE, merchant_listData)
        resolve(merchant_listData);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [FETCH_MERCHANTS_PAGINATED_LIST]({ commit, rootState }, { pageIndex, pagesize, filters }) {
    commit(UPDATE_MASTER_MERCHANT_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      MasterMerchantService.fetchMerchantPaginatedList(merchantUUID, iamportKey, pageIndex, pagesize, filters, environment).then((response) => {
        const merchant_list_content = response.content.map((merchant_list) => {
          return {
            status: merchant_list.merchant_status || "",
            name: merchant_list.name || "",
            email_address: merchant_list.email_address || "",
            ph_number: merchant_list.ph_number || "",
            acc_status: merchant_list.merchant_status || "",
            key: merchant_list.key || "",
            kyc_link: merchant_list.kyc_link || "",
            kyc_status: merchant_list.kyc_status || "",
            is_invite_merchant: merchant_list.is_invite_merchant || false,
            is_login_allowed: merchant_list.is_login_allowed || false,
            use_master_merchant_creds: merchant_list.use_master_merchant_creds || false,
          };
        });

        const merchant_listData = {
          content: merchant_list_content,
          total_elements: response.total_elements,
          total_pages: response.total_pages,
          size: response.size,
          page: response.page,
          number_of_elements: response.number_of_elements,
        }
        commit(ADD_MASTER_MERCHANT_PAGINATED_LIST_IN_STORE, merchant_listData)
        commit(UPDATE_MASTER_MERCHANT_FETCHING_STATE, false)
        resolve(merchant_listData);
      }).catch((error) => {
        commit(UPDATE_MASTER_MERCHANT_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [FETCH_INVITED_MERCHANTS_LIST]({ commit, rootState }) {
    commit(UPDATE_MASTER_MERCHANT_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      MasterMerchantService.fetchInvitedMerchantList(merchantUUID, iamportKey).then((response) => {
        const invited_merchant_list = response.content.map((merchant_list) => {
          return {
            status: merchant_list.status || "",
            email_id: merchant_list.email_address || "",
            invite_link: merchant_list.invite_link || "",
            created_at: moment(merchant_list.created_at).format('MMMM Do YYYY, h:mm:ss a'),
          };
        });

        const invited_merchant_listData = {
          content: invited_merchant_list
        }
        commit(ADD_INVITED_MASTER_MERCHANT_LIST_IN_STORE, invited_merchant_listData)
        commit(UPDATE_MASTER_MERCHANT_FETCHING_STATE, false)
        resolve(invited_merchant_listData);
      }).catch((error) => {
        commit(UPDATE_MASTER_MERCHANT_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [ACTIVE_INACTIVE_MERCHANT](context, { status,uuid }) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      MasterMerchantService.activeInactiveMerchant(merchantUUID, iamportKey,status,uuid).then((response) => {
        resolve(response);
        // context.dispatch(`${FETCH_MERCHANTS_PAGINATED_LIST}`, {
        //   pageIndex: 1,
        //   pagesize: 10,
        //   filters: {},
        // })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [MERCHANT_RESEND_KYC](context, { iamportKey }) {
    const environment = context.rootState.auth.environment;
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      MasterMerchantService.resendKYC(merchantUUID, iamportKey, environment).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [MERCHANT_RESEND_INVITE](context, { email }) {
    const environment = context.rootState.auth.environment;
    const uuid = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      MasterMerchantService.resendInvite(uuid, email, environment).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [DELETE_MERCHANT](context, { merchantKey }) {
    const environment = context.rootState.auth.environment;
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    const data = {
      "add_merchants": [],
      "remove_merchants": [merchantKey]
    }
    return new Promise((resolve, reject) => {
      MasterMerchantService.deleteMerchant(merchantUUID, data, iamportKey, environment).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  }
}

export const namespaced = true;
