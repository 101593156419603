import { InvoiceService } from '../../helpers/backend/invoice.service';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

import {
	FETCH_INVOICES,
	EXPORT_INVOICES,
	FETCH_INVOICES_TEMPLATES,
	FETCH_INVOICES_DETAILS,
	FETCH_INVOICES_TEMPLATE_DETAILS,
	INVOICES_REMINDER,
	INVOICE_VOID,
	INVOICE_UNVOID,
	MARK_SCHEDULE_SENT,
	CREATE_INVOICES,
	INVOICE_TIMELINE
} from '../actions.type'; 
import {
	ADD_INVOICES_IN_STORE,
	ADD_INVOICES_DETAILS_IN_STORE,
	ADD_INVOICES_TEMPLATES_IN_STORE,
	ADD_INVOICES_TIMELINE_IN_STORE
} from '../mutations.type';

export const state = {
	content: [],
	timeLineContent:[],
	total_elements: 0,
	total_pages: 0,
	size: 0,
	page: 0,
	number_of_elements: 0,
	isFetchingData:false,
	templateContent:[],
	detailsContent: {},
}

export const mutations = {
	[ADD_INVOICES_IN_STORE](state, newInvoiceData) {
		state.isFetchingData = newInvoiceData.isFetchingData;
		state.content = newInvoiceData.content;
		state.total_elements = newInvoiceData.total_elements;
		state.total_pages = newInvoiceData.total_pages;
		state.size = newInvoiceData.size;
		state.page = newInvoiceData.page;
		state.number_of_elements = newInvoiceData.number_of_elements;
	},
	[ADD_INVOICES_DETAILS_IN_STORE](state, invoiceDeatilsData) {
		if(invoiceDeatilsData.content.additional_costs && invoiceDeatilsData.content.additional_costs.length > 0) {
			invoiceDeatilsData.content.additional_costs = invoiceDeatilsData.content.additional_costs.map(cost => {
				cost.percent = (cost.charge_nature === "PERCENT");
				return cost;
			});
		}
		state.detailsContent = invoiceDeatilsData.content;
	},
	[ADD_INVOICES_TIMELINE_IN_STORE](state, invoiceTimeLineData) {
		state.timeLineContent = invoiceTimeLineData.content;
	},
	[ADD_INVOICES_TEMPLATES_IN_STORE](state, templateData) {
		state.templateContent = templateData;
	},
}

export const getters = {
}

export const actions = {
	[FETCH_INVOICES]({commit , rootState}, data) {
		const merchantUUID = rootState.auth.currentUser.merchantUuid;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		data.payload.environment = rootState.auth.environment;
		const iamportKey = rootState.auth.currentUser.iamportKey;
		return new Promise((resolve, reject) => {
			InvoiceService.getInvoiceList(merchantUUID,iamportKey, data, isMM).then((response) => {
				if(response.content === null) {
					response.content = [];
				}
				commit(ADD_INVOICES_IN_STORE, response)
				resolve(response);
			})
			.catch(reject);
		});
	},

	[FETCH_INVOICES_DETAILS]({commit , rootState}, { ref, submerchantKey }) {
		const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchantKey : rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		const environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.getInvoiceDetailsList(iamportKey, isMM, environment, ref).then((response) => {
				commit(ADD_INVOICES_DETAILS_IN_STORE, response)
				resolve(response);
			})
			.catch(reject);
		});
	},

	[FETCH_INVOICES_TEMPLATE_DETAILS]({rootState}, ref) {
		const iamportKey = rootState.auth.currentUser.iamportKey;

		return new Promise((resolve, reject) => {
			InvoiceService.getInvoiceTemplateDetails(iamportKey, ref).then((response) => {
				resolve(response);
			})
			.catch(reject);
		});
	},

	[MARK_SCHEDULE_SENT]({rootState}, payload) {
		// const iamportKey = rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		payload.environment  = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.markScheduleSent(isMM, payload).then((response) => {
				// commit(ADD_INVOICES_DETAILS_IN_STORE, response)
				resolve(response);
			})
			.catch(reject);
		});
	},
	
	[INVOICE_TIMELINE]({commit, rootState}, { invoice_ref, submerchantKey }) {
		const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchantKey : rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		const environment  = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.getInvoiceTimeline(iamportKey,isMM, invoice_ref, environment).then((response) => {
				commit(ADD_INVOICES_TIMELINE_IN_STORE, response)
				resolve(response);
			})
			.catch(reject);
		});
	},

	[INVOICES_REMINDER]({rootState}, ref) {
		// const iamportKey = rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		const environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.invoiceReminder(isMM, environment, ref).then((response) => {
				resolve(response);
			})
			.catch(reject);
		});
	},

	[INVOICE_VOID]({rootState}, ref) {
		// const iamportKey = rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		const environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.invoiceVoid(isMM, environment, ref).then((response) => {
				resolve(response);
			})
			.catch(reject);
		});
	},

	[INVOICE_UNVOID]({rootState}, ref) {
		// const iamportKey = rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		const environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.invoiceUnvoid(isMM, environment, ref).then((response) => {
				resolve(response);
			})
			.catch(reject);
		});
	},

	[FETCH_INVOICES_TEMPLATES]({commit , rootState}) {
		const iamportKey = rootState.auth.currentUser.iamportKey;
		const environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.getInvoiceTemplatesList(iamportKey, environment).then((response) => {
				commit(ADD_INVOICES_TEMPLATES_IN_STORE, response)
				resolve(response);
			})
			.catch(reject);
		});
	},
	[EXPORT_INVOICES]({commit, rootState}, data) {
		const iamportKey = rootState.auth.currentUser.iamportKey;
		const isMM = rootState.auth.currentUser.is_master_merchant;
		data.payload.environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			InvoiceService.exportInvoices(iamportKey, data, isMM).then((response) => {
			resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.status_message, { root: true })
			reject(errorObject);
			})
		})
	},
	[CREATE_INVOICES]({rootState}, payload) {
		const { iamportKey, iamportSecret } = rootState.auth.currentUser;
		// const merchantUUID = rootState.auth.currentUser.merchantUuid;
		// const isMM = rootState.auth.currentUser.is_master_merchant;
		payload.environment = rootState.auth.environment;

		let mainParams = "amount="+encodeURIComponent(payload.amount)+"&client_key="+encodeURIComponent(iamportKey)+"&currency="+encodeURIComponent(payload.currency)+"&failure_url="+encodeURIComponent(payload.failure_url)+"&merchant_invoice_ref="+encodeURIComponent(payload.merchant_invoice_ref)+"&sub_total="+encodeURIComponent(payload.sub_total)+"&success_url="+encodeURIComponent(payload.success_url);

		let hash = hmacSHA256(mainParams, iamportSecret);

		payload.portone_key = iamportKey;
		payload.signature_hash = Base64.stringify(hash);

		return new Promise((resolve, reject) => {
			InvoiceService.createInvoice(payload).then((response) => {
				console.log(response);
				resolve(response);
			})
			.catch(reject);
		});
	}
}

export const namespaced = true;
