export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import Headers from '../headers';
import moment from "moment";
export const MasterMerchantService = {
  fetchMerchantList,
  fetchMerchantPaginatedList,
  fetchInvitedMerchantList,
  inviteMerchantForManagment,
  inviteMerchantForOwnedType,
  activeInactiveMerchant,
  inviteMerchant,
  resendKYC,
  resendInvite,
  deleteMerchant
};

function fetchMerchantList(merchantUUID, iamportKey, pageIndex, pagesize, filters, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({pageIndex, pagesize, filters, environment})
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants?`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function fetchMerchantPaginatedList(merchantUUID, iamportKey, pageIndex, pagesize, filters, environment) {
  const toDate = new Date();
  const fromDate = new Date('2006-01-02');
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ page: pageIndex, to: moment(toDate).format('YYYY-MM-DD HH:mm:ss'), from: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), pagesize, filters, order: "desc", environment}),
    credentials: 'include',
  };
  // const queryParams = new URLSearchParams({ environment })
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants/paginated`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchInvitedMerchantList(merchantUUID, iamportKey) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants-invite`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function  activeInactiveMerchant (merchantUUID, iamportKey,status,uuid) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants/${uuid}/status/${status}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function  inviteMerchantForManagment (merchantUUID,email,merchant_type) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ email,merchant_type }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${merchantUUID}/merchants/invite`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function inviteMerchantForOwnedType( merchantUUID, iamportKey, email, merchant_type, name) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ merchant_name: name, merchant_loginid: email, merchant_password: iamportKey, country: "VN", is_master_merchant:false, master_merchant_type:merchant_type,master_merchant_uuid:merchantUUID}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/account/register`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
  
}

function inviteMerchant(merchantUUID, iamportKey, sendData) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(sendData),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/add-merchant`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
  
}

function resendKYC(merchantUUID, iamportKey, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/api/merchant/${iamportKey}/kyc/resend?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function resendInvite(uuid, email, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
    body: JSON.stringify({"email": email})
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${uuid}/merchants/invite/resend?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function deleteMerchant(merchantUUID, sendData, iamportKey, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(sendData),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  console.log("===", queryParams);
  return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  }); 
}
